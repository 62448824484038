
import RcDomainMap from "../../common/RcDomainMap.js";
import Employee from './Employee.js';
import EmployeeUtils from './EmployeeUtils.js';
import UserMap from "@/domain/model/user/UserMap.js";
import RoleMap from "@/domain/model/role/RoleMap.js";

export default class EmployeeMap extends RcDomainMap {
  static MODEL_NAME = "EmployeeMap";
  
  constructor(domain, data) {
    super(domain, data, EmployeeMap.MODEL_NAME);
  }

  first() {
    var values = Object.values(this.data);
    return new Employee(this.domain, values[0]);
  }
  
  copy() {
    var data = this._copy();
    return new EmployeeMap(this.domain, data);
  }
  
  sortByUserFirst() {
    var list = this.list();
    return list.sort(EmployeeUtils.SortByUserFirst);
  }
  
  users() {
    var values = new UserMap(this.domain);
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getEmployee(key);
      values.add(item.user());
    }
    return values;
  }

  roles() {
    var values = new RoleMap(this.domain);
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getEmployee(key);
      values.addAll(item.roles());
    }
    return values;
  }
  
  forUser(user) {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getEmployee(key);
      if (item.user().id() === user.id()) {
        return item;
      }
    }
    return new Employee(this.domain);
  }
  
  findByCompanyId(companyId) {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getEmployee(key);
      if (item.company().id() === companyId) {
        return item;
      }
    }
    return new Employee(this.domain);
  }
  
  list() {
    var values = [];
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getEmployee(key);
      values.push(item);
    }
    return values;
  }
  
  getEmployee(key) {
    var value = super.get(key);
    return new Employee(this.domain, value);
  }
 
  hasBeenExcluded() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getEmployee(key);
      if (item.hasBeenExcluded()) {
        return true;
      }
    }
    return false;
  }
  
  markExcluded() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getEmployee(key);
      item.withComplianceTypeExcluded();
    }
    return this;
  }
  
  markIncluded() {
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = this.getEmployee(key);
      item.withComplianceTypeIncluded();
    }
    return this;
  }
  
  pointers() {
    var map = new EmployeeMap(this.domain);
    var keys = this.keys();
    for (var index = 0; index < keys.length; index++) {
      var key = keys[index];
      var item = new Employee(this.domain, {'@rid': key});
      map.add(item);
    }
    return map;
  }
}